/* eslint react/no-danger: [0] */
import React from 'react';
import { Link } from 'react-router-dom';
import * as propTypes from '../../../propTypes';
import style from './style.styl';
import { newsList, news } from '../../../utils/links';
import formatDate from '../../../utils/formatDate';

import SocialButtons from '../../../components/SocialButtons';
import NewsImage from '../../components/NewsImage';

export default function NewsLast(props) {
  if (!Array.isArray(props.items.data) || !props.items.data.length) return null;
  // {/*<div className={style.content} dangerouslySetInnerHTML={{ __html: item.content }} />*/}
  return (
    <div>
      <Link to={newsList()} className={style.more}>Все новости и статьи &#62;&#62;</Link>
      <h3 className={style.header}>
        Новости и статьи
      </h3>
      <div className={style.items}>
        {props.items.data.map(item => (
          <div className={style.item} key={item.id}>
            <div className={style.date}>{formatDate(item.created)}</div>
            <Link to={news(item)} className={style.title}>{item.title}</Link>
            <p className={style.content}>{item.shortText}</p>
            <Link to={news(item)}>
              <NewsImage item={item} height={210} width={350} />
            </Link>
            <SocialButtons item={item} />
          </div>
        ))}
      </div>
    </div>
  );
}

NewsLast.propTypes = {
  items: propTypes.newsList,
};

