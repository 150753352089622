import React, { Component } from 'react';
import classnames from 'classnames';
import * as propTypes from '../../propTypes';
import style from './style.styl';
import { news } from '../../utils/links';
import partPhoto from '../../utils/partPhoto';
import AppConfig, { connectAppConfig } from 'src/shared/components/AppConfig';

class SocialButtons extends Component {
  static propTypes = {
    item:      propTypes.news,
    size:      propTypes.PropTypes.string,
    direction: propTypes.PropTypes.string,
  };

  static defaultProps = {
    size:      's',
    direction: 'horizontal',
  };

  constructor(props) {
    super(props);

    this.widget = null;
  }

  componentDidMount() {
    if (!window.Ya) return;

    const { item } = this.props;

    this.widget = window.Ya.share2(this.container, {
      content: {
        description: item.shortText,
        image:       partPhoto(item, '730x437', this.props.config.PHOTO_TOKEN),
        title:       item.title,
        url:         window.location.origin + news(item),
      },
      theme:   {
        services: 'vkontakte,facebook,twitter,odnoklassniki',
        counter:  true,
        size:     's',
      },
    });
  }

  componentWillUnmount() {
    if (this.widget) this.widget.destroy();
  }

  render() {
    const className = classnames(
      style.container,
      this.props.direction === 'vertical' && style.vertical,
      this.props.size === 'l' && style.size_l,
    );
    return <div className={className} ref={(c) => { this.container = c; }} />;
  }
}

export default connectAppConfig(SocialButtons);
