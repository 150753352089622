import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import style from './style.styl';
import * as propTypes from '../../../propTypes';

import Meta from '../../../components/Meta';
import BrandList from '../../components/BrandList';
import Title from '../../components/Title';
import NewsLast from '../../components/NewsLast';
import NotFound from '../../components/NotFound';

function BrandListContainer(props) {
  const carType = props.params.carType || 'cars';
  const brands = props.brands[carType];
  let typeTitle;
  let oppositeType;
  let oppositeTypeLink;
  if (carType === 'cars') {
    typeTitle = 'легковых';
    oppositeType = 'грузовых';
    oppositeTypeLink = 'trucks';
  } else if (carType === 'trucks') {
    typeTitle = 'грузовых';
    oppositeType = 'легковых';
    oppositeTypeLink = 'cars';
  } else {
    return <NotFound />;
  }

  const title = `Б/у запчасти для ${typeTitle} автомобилей`;

  return (
    <div>
      <Meta page="front" />

      <div>
        <Link to={`/${oppositeTypeLink}`} className={style.header_link}>
          Б/у запчасти для {oppositeType} автомобилей
        </Link>
        <Title>{title}</Title>
      </div>
      <BrandList brands={brands} />
      <NewsLast items={props.newsList} />
    </div>
  );
}

BrandListContainer.propTypes = {
  params:   propTypes.params,
  brands:   PropTypes.objectOf(propTypes.brands),
  newsList: propTypes.newsList,
};

const mapStateToProps = state => ({
  brands:   state.brands,
  newsList: state.newsList,
});
export default connect(mapStateToProps)(BrandListContainer);
