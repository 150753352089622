import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import style from './style.styl';

export default function Banner({ src, className }) {
  if (!src) return null;
  return (<img src={src} alt="" className={classnames(style.banner, className)} />);
}

Banner.propTypes = {
  src:       PropTypes.string,
  className: PropTypes.string,
};

