import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import style from './style';
import * as propTypes from '../../../propTypes';

import Meta from '../../../components/Meta';
import BrandList from '../../components/BrandList';
import ButtonGroup from '../../components/ButtonGroup';

class FrontPageContainer extends Component {
  static propTypes = {
    brands:  PropTypes.objectOf(propTypes.brands),
    history: propTypes.history,
  };

  static contextTypes = {
    router: PropTypes.object,
  };

  render() {
    const buttons = [
      { value: 'cars', label: 'Легковые', link: '/cars' },
      { value: 'trucks', label: 'Грузовые', link: '/trucks' },
    ];

    const value = 'cars';
    return (
      <div className={style.wrapper}>
        <Meta page="front" />
        {/*<div className={style.buttons}>*/}
        {/*  <ButtonGroup values={buttons} value={value} />*/}
        {/*</div>*/}
        <BrandList brands={this.props.brands.cars} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  brands: state.brands,
});
export default connect(mapStateToProps)(FrontPageContainer);
