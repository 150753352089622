import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import style from './style.styl';
import { catalogPage } from '../../../utils/links';

class BrandListItem extends Component {
  static propTypes = {
    brand: PropTypes.shape({
      id:            PropTypes.number,
      title:         PropTypes.string,
      titleTranslit: PropTypes.string,
      cargo:         PropTypes.bool,
      isPopular:     PropTypes.bool,
    }),
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      small:   false,
      loading: false,
    };

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.setState({ loading: true });
  }

  render() {
    const { brand } = this.props;
    const { loading } = this.state;
    const carType = brand.cargo ? 'trucks' : 'cars';
    const link = catalogPage({ carType, brand: brand.titleTranslit });
    return (
      <Link to={link} className={classNames(style.item, loading && style.loading)} onClick={this.onClick}>
        <div className={style.icon_wrapper}>
          <div className={classNames(carType, `${carType}-${brand.titleTranslit}`, style.icon)} />
        </div>
        <div className={classNames(style.text, { [style.small]: this.state.small })}>{brand.title}</div>
      </Link>
    );
  }
}

export default BrandListItem;
