import * as React     from 'react';
import PropTypes      from 'prop-types';
import { connect }    from 'react-redux';
// import { asyncConnect } from 'redux-connect';
import { Link }       from 'react-router-dom';
import style          from './style.styl';
import * as propTypes from '../../../propTypes';

import Meta                            from '../../../components/Meta';
import Title                           from '../../components/Title';
import BrandList                       from '../../components/BrandList';
import NewsLast                        from '../../components/NewsLast';
import Banner                          from '../../../components/Banner';
import AppConfig, { connectAppConfig } from 'src/shared/components/AppConfig';

class FrontPageContainer extends React.PureComponent {
  static propTypes = {
    brands:   PropTypes.objectOf(propTypes.brands),
    newsList: propTypes.newsList,
  };

  render() {
    const { brands, newsList, config } = this.props;
    return (
      <div>
        <div className={style.banner}>
          <Banner src={config.FRONT_PAGE_BANNER} />
        </div>
        <div>
          <Meta page="front" />
          <div className={style.center}>
            <h2>{config.FRONT_SUBTITLE}</h2>
            <Title>{config.FRONT_TITLE}</Title>
            <p>{config.FRONT_TEXT}</p>
          </div>
        </div>
        <BrandList brands={brands.cars} />
        <NewsLast items={newsList} />
      </div>
    );
  };
}

const mapStateToProps = state => ({
  brands:   state.brands,
  newsList: state.newsList,
});
export default connectAppConfig(connect(mapStateToProps)(FrontPageContainer));
