import * as React from 'react';
import * as propTypes from '../../../propTypes';
import partPhoto from '../../../utils/partPhoto';
import style from './style.styl';

import Icon from '../../../components/Icon';
import AppConfig, { connectAppConfig } from 'src/shared/components/AppConfig';

class NewsImage extends React.PureComponent {
  propTypes = {
    item:   propTypes.newsList,
    width:  propTypes.PropTypes.number,
    height: propTypes.PropTypes.number,
  };

  render() {
    const { item, width, height } = this.props;
    if (!item.image) {
      return (
        <div className={style.placeholder} style={{ width, height }}>
          <Icon glyph="camera" className={style.no_photo} />
        </div>
      );
    }
    return (
      <img src={partPhoto(item.image, `${width}x${height}`, this.props.config.PHOTO_TOKEN)} width={width} height={height} alt={item.title} />
    );
  }
}

export default connectAppConfig(NewsImage);
